import { HomeEngineProps } from '@/components/sections/einstein/home/engine';
import { SectionEinsteinGearProps } from '@/components/sections/einstein/home/gear';
import { type HomeLineupProps } from '@/components/sections/einstein/home/lineup';
import type { IMojitoSpecial } from '@/components/sections/mojito/special';
import type { IMY24HeroBanner } from '@/components/sections/my24/home/hero-banner';
import type { IMY24HighlightCarousel } from '@/components/sections/my24/home/highlight-carousel';
import type { ISplashPage } from '@/components/sections/splash';
import { getCta } from '@/contents/lcv/product/maxforce/shared/cta';
import { HOME_LINEUP_DATA } from '@/contents/lcv/product/maxforce/shared/lineup';

export const META_TAG_DATA = {
  title: 'อีซูซุประเทศไทย Isuzu Thailand Official',
  description:
    'ดูข้อมูลรถอีซูซุ D-Max และ MU-X คำนวณค่างวด เปรียบเทียบรุ่นรถ ขอสินเชื่อ ดูข้อเสนอพิเศษต่างๆ พร้อมทั้งดาวน์โหลดโบรชัวร์ และค้นหาศูนย์บริการใกล้บ้านท่าน',
  img: {
    url: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltb8ebe5359f8c9db3/62fb311dea0d864c464abcaf/OG_ISUZU.jpg',
  },
};

export const HIGHLIGHT_CAROUSEL_DATA: IMY24HighlightCarousel = {
  active: true,
  customField: 'mojito',
  headline: {
    decorate: false,
    title: '<div class="text-lime">THE PEAK OF</div><span>RS DESIGN</span>',
    description:
      'การออกแบบครั้งใหม่! ที่พลิกโฉม MU-X <span>ให้โดดเด่น</span>และสปอร์ตผ่าน<span>เส้นสาย</span>ที่มีความ DYNAMIC <span>รอบคัน</span>มาพร้อมสีสันอันเป็นเอกลักษณ์เฉพาะ ทําให้ทุก<span>จังหวะพีค</span>... ชัดเจนสูงสุด',
    buttons: [],
  },
  items: [
    {
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt268e2f7bd89fcb86/6665618f3dcdf676032c9cf6/rs-exterior-highlight-1-grille.jpg',
        alt: 'MU-X กระจังหน้าใหม่ พร้อมด้วยสัญลักษณ์ RS',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt5aef24c0771b3d07/6665618fa05faea6cac2eb8d/rs-exterior-highlight-1-grille-desktop.jpg',
        alt: 'MU-X กระจังหน้าใหม่ พร้อมด้วยสัญลักษณ์ RS',
        width: 600,
        height: 338,
      },
      title: 'BLACK DIAMOND GRILLE',
      description:
        '<p>ทรงพลังด้วยกระจังหน้าใหม่! BLACK DIAMOND GRILLE พร้อมสะท้อนความ<span>พีค</span>ด้วย<span>สัญลักษณ์ RS</span> และวัสดุ <span>Black Chrome</span></p>',
      buttons: [
        {
          label: 'ดูรายละเอียด',
          url: '/isuzu-mu-x',
          icon: 'arrow-forward',
          color: 'button-white',
        },
      ],
    },
    {
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt4d5a77bc57cbcfbc/6665618f3a1c5f1ff9b80c35/rs-exterior-highlight-2-dynamic-blade.jpg',
        alt: 'MU-X Exterior ไฟหน้าใหม่ DYNAMIC BLADE',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt82c91c885f86381c/6665618f1946587db74c7bb8/rs-exterior-highlight-2-dynamic-blade-desktop.jpg',
        alt: 'MU-X Exterior ไฟหน้าใหม่ DYNAMIC BLADE',
        width: 600,
        height: 338,
      },
      title: 'ไฟหน้าดีไซน์ <span>DYNAMIC BLADE</span>',
      description: '<p>โฉบเฉี่ยวด้วยไฟหน้าใหม่! <span>DYNAMIC BLADE</span></p>',
      buttons: [
        {
          label: 'ดูรายละเอียด',
          url: '/isuzu-mu-x',
          icon: 'arrow-forward',
          color: 'button-white',
        },
      ],
    },
    {
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltfcbd8ba2741df2b1/6665618fc97e381c82f15f8d/rs-exterior-highlight-3-embrace-line.jpg',
        alt: 'MU-X Exterior ไฟท้าย EMBRACE LINE',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt1bee52cd38cdbae3/6665618ffe5232e481287911/rs-exterior-highlight-3-embrace-line-desktop.jpg',
        alt: 'MU-X Exterior ไฟท้าย EMBRACE LINE',
        width: 600,
        height: 338,
      },
      title: 'ไฟท้ายดีไซน์ใหม่!',
      description:
        '<p>ชุดไฟท้ายดีไซน์สปอร์ตด้วยเส้น <span>EMBRACE LINE</span></p>',
      buttons: [
        {
          label: 'ดูรายละเอียด',
          url: '/isuzu-mu-x',
          icon: 'arrow-forward',
          color: 'button-white',
        },
      ],
    },
    {
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt80c0278b764c7669/665e7a8738df322870618b7f/rs-exterior-highlight-4-roof.jpg',
        alt: 'MU-X Exterior สีใหม่ EIGER GRAY OPAQUE และหลังคาดำ GLOSS BLACK ROOF',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt47afb51f534a9796/665e7a87a3c3fee7e64d1dca/rs-exterior-highlight-4-roof-desktop.jpg',
        alt: 'MU-X Exterior สีใหม่ EIGER GRAY OPAQUE และหลังคาดำ GLOSS BLACK ROOF',
        width: 600,
        height: 338,
      },
      title: 'GLOSS BLACK ROOF',
      description:
        '<p>ดีไซน์ใหม่! เท่เร้าใจอารมณ์สปอร์ต <span>ด้วยหลังคาดำ</span> <span>GLOSS BLACK ROOF</span></p>',
      buttons: [
        {
          label: 'ดูรายละเอียด',
          url: '/isuzu-mu-x',
          icon: 'arrow-forward',
          color: 'button-white',
        },
      ],
    },
    {
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt5b1ea76b0e71b919/665e7a87d52e440c01ac70a2/rs-exterior-highlight-5-alloy-wheel.jpg',
        alt: 'MU-X Exterior ล้ออัลลอยใหม่ RS DESIGN ขนาด 20 นิ้ว',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltcb8e1b1c5bb3a62d/665e7a87742a0c628d7c65ca/rs-exterior-highlight-5-alloy-wheel-desktop.jpg',
        alt: 'MU-X Exterior ล้ออัลลอยใหม่ RS DESIGN ขนาด 20 นิ้ว',
        width: 600,
        height: 338,
      },
      title: 'ล้ออัลลอย <span>RS DESIGN</span>',
      description:
        '<p>ล้ออัลลอยใหม่! RS DESIGN ขนาด 20 นิ้ว <span>เพิ่มความ</span>สปอร์ตด้วย <span>FENDER GARNISH สีดำ</span></p>',
      buttons: [
        {
          label: 'ดูรายละเอียด',
          url: '/isuzu-mu-x',
          icon: 'arrow-forward',
          color: 'button-white',
        },
      ],
    },
    {
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltf50b28b9fc380a72/665e7a88dbfae913b60f993c/rs-interior-highlight-6-interior.jpg',
        alt: 'MU-X Interior ภายในห้องโดยสารให้พีคกว่าเดิมด้วยโทนสีดำใหม่',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt81e53cd3fa325652/665e7a8870d09b931dd62db7/rs-interior-highlight-6-interior-desktop.jpg',
        alt: 'MU-X Interior ภายในห้องโดยสารให้พีคกว่าเดิมด้วยโทนสีดำใหม่',
        width: 600,
        height: 338,
      },
      title: 'ภายในใหม่! RS Style',
      description:
        '<p>พีคกว่าเดิมด้วยห้องโดยสารโทนสีดำใหม่! <span>พร้อมตกแต่ง</span>ด้วย MATTE SILVER GARNISH</p>',
      buttons: [
        {
          label: 'ดูรายละเอียด',
          url: '/isuzu-mu-x',
          icon: 'arrow-forward',
          color: 'button-white',
        },
      ],
    },
    {
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt9a699e8fb9f4232c/665e8c1d85966b28717175d3/rs-interior-highlight-7-seat.jpg',
        alt: 'MU-X Interior เบาะนั่งดีไซน์สปอร์ตใหม่',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt1df3fe7a625918fc/665e7a8885966bd2d6717577/rs-interior-highlight-7-seat-desktop.jpg',
        alt: 'MU-X Interior เบาะนั่งดีไซน์สปอร์ตใหม่',
        width: 600,
        height: 338,
      },
      title: 'เบาะนั่งดีไซน์<span>สปอร์ตใหม่!</span>',
      description:
        '<p>เพอร์เฟกต์ทุกองศา นั่งสบายโอบรับสรีระ <span>โดดเด่น</span>ด้วยการเดินด้ายสี <span>LIME GREEN</span> และ<span>สัญลักษณ์ RS</span></p>',
      buttons: [
        {
          label: 'ดูรายละเอียด',
          url: '/isuzu-mu-x',
          icon: 'arrow-forward',
          color: 'button-white',
        },
      ],
    },
    {
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltf412208d4347e50d/665e7a8702ed7f6da6e82da7/rs-interior-highlight-8-ambient-light.jpg',
        alt: 'MU-X Interior RED AMBIENT LIGHT',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt1f75e8b37f2a94c3/665e7a87ba8809cb9253aeb6/rs-interior-highlight-8-ambient-light-desktop.jpg',
        alt: 'MU-X Interior RED AMBIENT LIGHT',
        width: 600,
        height: 338,
      },
      title: 'RED AMBIENT LIGHT',
      description:
        '<p>เร้าใจทุกช่วงเวลา ด้วยบรรยากาศภายใน <span>RED AMBIENT LIGHT</span></p>',
      buttons: [
        {
          label: 'ดูรายละเอียด',
          url: '/isuzu-mu-x',
          icon: 'arrow-forward',
          color: 'button-white',
        },
      ],
    },
  ],
};

export const SPLASH_DATA: ISplashPage = {
  contentGradient: false,
  headline: {
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt1000e0ef6a3e0c5e/67371a68d67c0a70f4e059d1/the-force-of-future-logo.png',
      alt: 'NEW! 2.2 Ddi MAXFORCE พลังใหม่...กำหนดโลก',
      width: 269,
      height: 24,
    },
    imageDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt1000e0ef6a3e0c5e/67371a68d67c0a70f4e059d1/the-force-of-future-logo.png',
      alt: 'NEW! 2.2 Ddi MAXFORCE พลังใหม่...กำหนดโลก',
      width: 313,
      height: 28,
    },
    buttons: [
      {
        label: 'เข้าสู่เว็บไซต์',
        color: 'button-white',
        border: true,
      },
      {
        label: 'ดูรายละเอียดเครื่องยนต์',
        url: '/engine',
        color: 'button-white',
      },
    ],
  },
  bg: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltce2bd188d3920910/6735b28b064225346edcb5a9/splash-mobile.jpg',
    alt: 'NEW! 2.2 Ddi MAXFORCE The FORCE of FUTURE',
    width: 360,
    height: 640,
  },
  bgDesktop: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt1709f3fb96bb0367/672daab71701718f3cf004e4/splash-desktop.jpg',
    alt: 'NEW! 2.2 Ddi MAXFORCE The FORCE of FUTURE',
    width: 1440,
    height: 700,
  },
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt3c7c7cca88e0118c/67371ad92f94ea19547268ff/logo-22-ddi-maxforce-splash.png',
    alt: 'NEW! 2.2 Ddi MAXFORCE',
    width: 234,
    height: 71,
  },
  imageDesktop: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt3c7c7cca88e0118c/67371ad92f94ea19547268ff/logo-22-ddi-maxforce-splash.png',
    alt: 'NEW! 2.2 Ddi MAXFORCE',
    width: 320,
    height: 104,
  },
};

export const HERO_BANNER_DATA: IMY24HeroBanner = {
  headline: {
    title: 'อีซูซุประเทศไทย Isuzu Thailand Official',
  },
  items: [
    {
      title: 'พลังใหม่...<span>กำหนดโลก!</span>',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt235fa9191aa7caeb/6731a42da3eb8e5c5d3e1946/cover-home-engine-hightlight-mobile.jpg',
        alt: 'ISUZU Ddi MAXFORCE พลังใหม่...กำหนดโลก!',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt29f6788d3278b45f/6731a42c53e3c4b935b3b398/cover-home-engine-hightlight-desktop.jpg',
        alt: 'ISUZU Ddi MAXFORCE พลังใหม่...กำหนดโลก!',
        width: 600,
        height: 338,
      },
      video: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt30f7381f069aadf3/6731a42da6bfe7a9209c0b50/home-engine-hightlight-mobile.mp4',
      },
      videoDesktop: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt2dbddb5bcabb7d88/6731a42ddec4efd82a7ce376/home-engine-hightlight-desktop.mp4',
      },
      logo: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltf774eb2214b37df3/672dad0277c00dc82ea13130/logo-ddi-maxforce.svg',
        alt: 'ISUZU Ddi MAXFORCE พลังใหม่...กำหนดโลก!',
        width: 192,
        height: 48,
      },
      logoDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltf774eb2214b37df3/672dad0277c00dc82ea13130/logo-ddi-maxforce.svg',
        alt: 'ISUZU Ddi MAXFORCE พลังใหม่...กำหนดโลก!',
        width: 300,
        height: 75,
      },
      youtube: {
        label: 'ดูวิดีโอแบบเต็ม',
        url: 'hoa8qHTySjE',
        icon: 'play',
        size: 'lg',
      },
      buttons: [
        {
          label: 'ทดลองขับ',
          url: '/register',
          color: 'button-white',
          border: true,
        },
      ],
    },
    {
      title:
        'ใหม่! อีซูซุ ดีแมคซ์ ปิกอัพ 4 ประตู <span>พลังใหม่...กำหนดโลก!</span>',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltd3e4cac96914141f/672c44cae5f14ab4d160379a/cover-product-hightlight-4-door-mobile.jpg',
        alt: 'More Fuel Efficient Than Ever',
        width: 600,
        height: 450,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blte7a2462e9a24a60f/672c44caeeab5c6e9d32020f/cover-product-hightlight-4-door-desktop.jpg',
        alt: 'More Fuel Efficient Than Ever',
        width: 600,
        height: 450,
      },
      video: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltf54887b27b7f8ced/672c44ca70f8d4544f5e63d7/product-hightlight-4-door-mobile.mp4',
      },
      videoDesktop: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt39a7844b3a24e126/672c44cb6f271f0a45b07f18/product-hightlight-4-door-desktop.mp4',
      },
      logo: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltbb29f729cd7c11e7/673739e1db20805ff40cb11e/logo-new-isuzu-d-max.svg',
        alt: 'NEW ISUZU D-MAX',
        width: 320,
        height: 80,
      },
      buttons: [
        {
          label: 'ดูรายละเอียด D-MAX',
          url: '/isuzu-pick-up-4-door',
          icon: 'arrow-forward',
          color: 'button-white',
          border: true,
        },
      ],
    },
    {
      title:
        'ใหม่! อีซูซุ ดีแมคซ์ ปิกอัพ 2 ประตู <span>พลังใหม่...กำหนดโลก!</span>',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt426afae237ed2947/672c4549eeab5c4e1b320215/cover-product-hightlight-2-door-mobile.jpg',
        alt: 'More Powerful',
        width: 600,
        height: 450,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt37a435708a476ff9/672c45493d58d0ab4f7c664f/cover-product-hightlight-2-door-desktop.jpg',
        alt: 'More Powerful',
        width: 600,
        height: 450,
      },
      video: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltd938f3ede24c11ab/672c454a7336225b599c6e36/product-hightlight-2-door-mobile.mp4',
      },
      videoDesktop: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt43735c7d3aabb2d2/672c454987b5033456b05e7d/product-hightlight-2-door-desktop.mp4',
      },
      logo: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltbb29f729cd7c11e7/673739e1db20805ff40cb11e/logo-new-isuzu-d-max.svg',
        alt: 'NEW ISUZU D-MAX',
        width: 320,
        height: 80,
      },
      buttons: [
        {
          label: 'ดูรายละเอียด D-MAX',
          url: '/isuzu-pick-up-2-door',
          icon: 'arrow-forward',
          color: 'button-white',
          border: true,
        },
      ],
    },
    {
      title:
        'NEW! MU-X THE NEXT PEAK <span>กำหนดจุดสูงสุดใหม่</span>ที่เหนือกว่า',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt1354aaafd8f77b2b/672c43ed41d576173d94b34f/cover-product-hightlight-mu-x-mobile.jpg',
        alt: 'Faster',
        width: 600,
        height: 450,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt13d97e66852eeaa9/672c43ed20d86e845fb37330/cover-product-hightlight-mu-x-desktop.jpg',
        alt: 'Faster',
        width: 600,
        height: 450,
      },
      video: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt3ffa9e8da448ed53/672c43eeaa08b276c23c5bac/product-hightlight-mu-x-mobile.mp4',
      },
      videoDesktop: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt2924fa1d8277c1a9/672c43ed817eeb1861eb1ccd/product-hightlight-mu-x-desktop.mp4',
      },
      logo: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt582781a2b1def043/665fdfa4ce5a1c60960c9766/logo-rs.png',
        alt: 'MU-X The next peak',
        width: 300,
        height: 72,
      },
      logoDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltba92af469e5556e4/665ec223ce5a1c82720c8d14/logo-isuzu-mu-x.png',
        alt: 'MU-X The next peak',
        width: 640,
        height: 281,
      },
      buttons: [
        {
          label: 'ดูรายละเอียด MU-X',
          url: '/isuzu-mu-x',
          icon: 'arrow-forward',
          color: 'button-white',
          border: true,
        },
      ],
    },
    {
      title: 'ใหม่! อีซูซุ วี-ครอส 4x4 <span>พลังใหม่...กำหนดโลก!</span>',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltea1218288e823af3/66b584149519f07a45729977/video-v-cross-poster-mobile.jpg',
        alt: 'NEW! ISUZU V-CROSS 4x4',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt91fa3eaafe6127a1/651d14803091588fb9f08701/video-v-cross-poster.jpg',
        alt: 'NEW! ISUZU V-CROSS 4x4',
        width: 600,
        height: 338,
      },
      video: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltea1218288e823af3/651d2b7f2b65937d39c575b6/video-v-cross-mobile.mp4',
      },
      videoDesktop: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blte4dbac641c915dd1/651d27fb1d2dc3b0c7fb9348/video-v-cross-desktop.mp4',
      },
      logo: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt11fc45d526e45dbb/6731c29820ed6c51d1a5141c/logo-v-cross.png',
        alt: 'NEW ISUZU V-CROSS 4x4',
        width: 320,
        height: 80,
      },
      buttons: [
        {
          label: 'ดูรายละเอียด D-MAX',
          url: '/isuzu-v-cross',
          icon: 'arrow-forward',
          color: 'button-white',
          border: true,
        },
      ],
    },

    {
      title: 'ใหม่! อีซูซุ ดีแมคซ์ สปาร์ค <span>พลังใหม่...กำหนดโลก!</span>',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt6c44a797500eabf0/672c7ab1853e02114363e5f1/spark-mobile-banner.jpg',
        alt: 'NEW ISUZU SPARK',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt2d59bbf9b8d8d134/672c7aa5af3729e562038843/spark-desktop-banner.jpg',
        alt: 'NEW ISUZU SPARK',
        width: 600,
        height: 338,
      },
      logo: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt314f4bd4c55e7e9f/6737388e8a260cf6afa5de72/logo-new-isuzu-d-max-spark.svg',
        alt: 'NEW ISUZU D-MAX SPARK',
        width: 320,
        height: 80,
      },
      buttons: [
        {
          label: 'ดูรายละเอียด D-MAX',
          url: '/isuzu-spark',
          icon: 'arrow-forward',
          color: 'button-white',
          border: true,
        },
      ],
    },
    {
      title: 'อีซูซุ เอ็กซ์-ซีรี่ส์ <span>แรง...ทะลุเวิร์ส</span>',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta1e226dd3314117a/65a9b35acdbb968a6bc504aa/x-series-cover.jpg',
        alt: 'Isuzu X-series Gotta Xross The Line!',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt53843e5680963db7/65a9b35a14eace44efa5b1aa/x-series-cover-desktop.jpg',
        alt: 'Isuzu X-series Gotta Xross The Line!',
        width: 600,
        height: 338,
      },
      video: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltc6d8f4a5d1a7c758/65a9b35c7fc0005be4223f29/x-series-video.mp4',
      },
      videoDesktop: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt05219b63a330f641/65a9b35c573161bd53cc7a1f/x-series-video-desktop.mp4',
      },
      logo: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt0ce9a8fb9081feb0/65b64afe08561627ea5e07b4/logo-isuzu-x-series.png',
        alt: 'Isuzu X-series Gotta Xross The Line!',
        width: 300,
        height: 72,
      },
      youtube: {
        label: 'ดูวิดีโอแบบเต็ม',
        url: '71hG0N3FGL4',
        icon: 'play',
        size: 'lg',
      },
      buttons: [
        {
          label: 'ดูรายละเอียด X-SERIES',
          url: '/isuzu-x-series',
          icon: 'arrow-forward',
          color: 'button-white',
          border: true,
        },
      ],
    },
  ],
};

export const SPECIAL_DATA: IMojitoSpecial = {
  startPolygonNumber: 7,
  slidePolygonSize: {
    portrait: '135vw',
    portraitXs: '75vh',
    landscape: '110vh',
    landscape1200: '125vh',
    polygonHeightScale: 1.15546218487,
    backgroundScale: 1.28,
  },
  slides: [
    {
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt814dcea6510efada/665de3d002ed7f637fe82747/special-section-1.jpg',
        alt: 'NEW! MU-X THE NEXT PEAK จุดสูงสุดใหม่... กับชีวิตที่เหนือกว่า',
        width: 1920,
        height: 1920,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltea75361f8ced80d8/665de3d085966b7103716e57/special-section-1-desktop.jpg',
        alt: 'NEW! MU-X THE NEXT PEAK จุดสูงสุดใหม่... กับชีวิตที่เหนือกว่า',
        width: 1920,
        height: 3560,
      },
      startAngle: 65,
      endAngle: 45,
      backgroundColor: 'rgb(88 ,107, 119)',
    },
    {
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt4487c80c0b76861a/6666bb8ffe5232202c287b98/special-section-2-v2.jpg',
        alt: 'NEW! MU-X THE NEXT PEAK จุดสูงสุดใหม่... กับชีวิตที่เหนือกว่า',
        width: 1920,
        height: 1920,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt54ad87613dcee68b/6666bb8ffe523220e2287b94/special-section-2-desktop-v2.jpg',
        alt: 'NEW! MU-X THE NEXT PEAK จุดสูงสุดใหม่... กับชีวิตที่เหนือกว่า',
        width: 1920,
        height: 3560,
      },
      startAngle: 90,
      endAngle: 75,
      backgroundColor:
        'linear-gradient(98deg, rgb(57, 82, 107) 37%, rgb(108, 103, 90) 53%)',
    },
    {
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt092be2dad607ed0e/66694b4a3ab7db146b188afe/special-section-3.jpg',
        alt: 'NEW! MU-X THE NEXT PEAK จุดสูงสุดใหม่... กับชีวิตที่เหนือกว่า',
        width: 1920,
        height: 1920,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltea5af028939ed057/66694b4a0c0c3ef5c903605a/special-section-3-desktop.jpg',
        alt: 'NEW! MU-X THE NEXT PEAK จุดสูงสุดใหม่... กับชีวิตที่เหนือกว่า',
        width: 1920,
        height: 3560,
      },
      startAngle: 65,
      endAngle: 45,
      backgroundColor:
        'linear-gradient(321deg, rgb(28 50 65) 42%, rgb(101 183 222) 68%)',
      imageOffset: {
        portrait: {
          x: 0,
          y: 17,
        },
        landscape: {
          x: 0,
          y: 55,
        },
      },
    },
  ],
  lead: {
    url: '/isuzu-mu-x',
    label: 'ดูรายละเอียด MU-X',
    test: {
      dataTest: 'mojito_special_ctaButton',
    },
    tracking: {
      dataTrack: 'lcv-home',
      dataTrackSection: 'mojito-special',
      dataTrackText: 'ดูรายละเอียด MU-X',
      dataTrackUrl: '/isuzu-mu-x',
    },
  },
  title: {
    lg: {
      firstLine: 'MU-X',
      secondLine: 'THE NEXT PEAK',
    },
    maxlg: {
      firstLine: 'MU-X',
      secondLine: 'THE NEXT PEAK',
    },
  },
};

export const LINEUP_CAROUSEL_DATA: HomeLineupProps = {
  items: HOME_LINEUP_DATA,
  dataTrack: {
    dataTrack: 'lcv-home',
    dataTrackSection: 'lineup',
  },
};

export const CTA_DATA = {
  ...getCta('home'),
};

export const GEAR_DATA: SectionEinsteinGearProps = {
  video: {
    src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt95745040e21913ae/67319e8fdec4ef1baf7ce368/home-gear-hightlight-mobile.mp4',
  },
  videoDesktop: {
    src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltab6e3f1b7e3166f9/67319e78073fa7289a26f06f/home-gear-hightlight-desktop.mp4',
  },
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt935dabab1a02e321/67319e3be8347de6e40a3b4d/cover-home-gear-hightlight-mobile.jpg',
    alt: 'Gear Gihtlight',
    width: 1280,
    height: 720,
  },
  imageDesktop: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt6d7b1a95379823a4/67319e0f824c1a0a698da592/cover-home-gear-hightlight-desktop.jpg',
    alt: 'Gear Gihtlight',
    width: 1280,
    height: 720,
  },
  headline: {
    preTitle: 'ใหม่! เกียร์อัตโนมัติ <span>8 สปีด</span>',
    title: 'แบบ REV TRONIC',
    description:
      'ออกแบบอัตราทดให้ส่งกำลังต่อเนื่อง <span>สัมพันธ์กับแรงบิด</span> <span>ที่สูงตั้งแต่ช่วงออกตัว</span><span>และขณะเร่งแซง</span><span>ของเครื่อง 2.2 Ddi MAXFORCE</span>',
    buttons: [
      {
        type: 'border',
        label: 'ทดลองขับ',
        url: '/register',
        color: 'button-white',
        isMinWidth: true,
      },
    ],
  },
};

export const ENGINE_DATA: HomeEngineProps = {
  title: 'THE <br class="maxsm">FORCE <br class="sm">OF<br>FUTURE',
  detailItems: [
    {
      title: 'แรงขึ้น',
    },
    {
      title: 'เร็วขึ้น',
    },
    {
      title: 'ประหยัด<br class="maxsm">น้ำมัน<br class="sm">ยิ่งขึ้น',
    },
  ],
  cta: {
    link: '/engine',
    label: 'ดูรายละเอียดเครื่องยนต์',
    test: {
      dataTest: 'home_cta_engine',
    },
    tracking: {
      dataTrack: 'lcv-home',
      dataTrackSection: 'home_cta_engine',
      dataTrackText: 'ดูรายละเอียดเครื่องยนต์',
      dataTrackUrl: '/engine',
    },
  },
};

import { LegacyRef, useEffect, useRef, useState } from 'react';
import { Keyboard, Pagination } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperRef, SwiperSlide } from 'swiper/react';

import { useMediaQuery } from '@/components/hooks/useMediaQuery';
import { useModal } from '@/components/hooks/useModal';
import { BannerContentWithLogo } from '@/components/shared/einstein/banner-content-with-logo';
import { PaginationBar } from '@/components/shared/einstein/pagination-bar';
import { PlayerCursorButton } from '@/components/shared/einstein/player-cursor-button';
import { Text } from '@/components/shared/element/text';
import { Box } from '@/components/shared/layout/box';
import { Container } from '@/components/shared/layout/container';
import { generateBannerImageSources } from '@/components/shared/media/image/generate-sources';
import { Video } from '@/components/shared/media/video';
import { playVideoWithPromiseHandle } from '@/components/shared/utility/video';
import { config, styled } from '@/stitches.config';
import { IHeadline, IItem, IMy24Tracking } from '@/types/shared';

const HeroSection = styled('section', {
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  bc: '$black',
  '@maxlg': {
    '@media (orientation: portrait)': {
      height: 'calc(100vh - var(--header-height))',
      aspectRatio: '2 / 3',
      '@supports (height: 100svh)': {
        height: 'calc(100svh - var(--header-height))',
      },
    },
    '@media (orientation: landscape)': {
      aspectRatio: '3 / 4',
    },
  },
  '@lg': {
    minHeight: 480,
    height: 'calc(100vh - var(--header-height))',
  },
  '& .swiper': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    transform: 'translateZ(0)',
  },
  '& .swiper-slide': {
    transform: 'translateZ(0)',
    overflow: 'hidden',
  },
  '& .swiper-slide-duplicate': {
    opacity: 0,
    '&[data-anim="fade"]': {
      opacity: 1,
    },
  },
});

const HidePaginationBarWrapper = styled(Box, {
  display: 'none',
  '@lg': {
    position: 'absolute',
    inset: '-$space-12',
    pointerEvents: 'all',
    display: 'block',
  },
});

export interface LCVHomeBannerProps {
  headline: IHeadline;
  items?: IItem[];
  isCloseSplash?: boolean;
  dataTrack?: IMy24Tracking;
}

const LCVHomeBanner = ({
  headline: { title },
  items = [],
  isCloseSplash = false,
  dataTrack = 'lcv-home',
}: LCVHomeBannerProps) => {
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const [swiperMedia, setSwiperMedia] = useState<SwiperClass | null>(null);
  const [startAnimatePagination, setStartAnimatePagination] =
    useState<boolean>(false);
  const [pauseVideo, setPauseVideo] = useState<boolean>(false);
  const swiperRef = useRef<SwiperClass | null>(null);
  const areaRef = useRef<HTMLDivElement | null>(null);

  const isPortrait = useMediaQuery(config.media.portrait);

  const onResetActiveVideo = () => {
    if (!swiperMedia) return;

    const INDEX_SLIDE = swiperMedia.activeIndex;
    swiperMedia.slides &&
      swiperMedia.slides.forEach((o, i) => {
        if (i === INDEX_SLIDE) {
          const VIDEO = o.querySelector('video') as HTMLVideoElement;

          if (VIDEO) {
            VIDEO.pause();
            VIDEO.currentTime = 0;
            setStartAnimatePagination(false);
          }
        }
      });
  };

  const onPlayMedia = () => {
    if (
      !swiperMedia ||
      pauseVideo ||
      !isCloseSplash ||
      !swiperMedia.pagination
    ) {
      return;
    }

    const INDEX_SLIDE = swiperMedia?.activeIndex;
    const ACTIVE_SLIDE = swiperMedia?.slides?.[swiperMedia?.activeIndex];
    const ACTIVE_VIDEO = ACTIVE_SLIDE?.querySelector(
      'video'
    ) as HTMLVideoElement;

    // reset all video except active
    if (swiperMedia.slides) {
      swiperMedia.slides.forEach((o, i) => {
        if (i !== INDEX_SLIDE) {
          const VIDEO = o.querySelector('video') as HTMLVideoElement;

          if (VIDEO) {
            VIDEO.pause();
          }
        }
      });
    }
    if (ACTIVE_VIDEO) {
      // NOTE: video
      const BULLETS = swiperMedia.pagination?.bullets;

      const playVideo = () => {
        if (BULLETS) {
          const DURATION = ACTIVE_VIDEO.duration || 8;
          BULLETS?.[swiperMedia.realIndex]?.style.setProperty(
            '--duration',
            `${DURATION}s`
          );
        }

        setStartAnimatePagination(false);
        playVideoWithPromiseHandle(ACTIVE_VIDEO);
        setStartAnimatePagination(true);
      };
      if (ACTIVE_VIDEO.readyState >= 2) {
        playVideo();
      } else {
        ACTIVE_VIDEO.addEventListener(
          'loadedmetadata',
          () => {
            playVideo();
          },
          { once: true }
        );
      }
    } else {
      // NOTE: image
      setStartAnimatePagination(true);
    }
  };

  const onPauseVideo = () => {
    if (!swiperMedia) return;

    setPauseVideo(true);
    swiperMedia.slides &&
      swiperMedia.slides.forEach((o) => {
        const VIDEO = o.querySelector('video') as HTMLVideoElement;
        if (VIDEO) {
          VIDEO.pause();
        }
      });
  };

  const onPlayVideo = () => {
    if (!swiperMedia) return;

    setPauseVideo(false);
    const INDEX_SLIDE = swiperMedia?.activeIndex;
    const ACTIVE_SLIDE = swiperMedia?.slides?.[INDEX_SLIDE];
    const ACTIVE_VIDEO = ACTIVE_SLIDE.querySelector(
      'video'
    ) as HTMLVideoElement;
    if (ACTIVE_VIDEO) {
      playVideoWithPromiseHandle(ACTIVE_VIDEO);
    }
  };

  // NOTE: init
  useEffect(() => {
    if (!swiperMedia || swiperMedia.destroyed) return;
    swiperMedia.slides?.forEach((slide) => {
      if (slide.classList.contains('swiper-slide-duplicate')) {
        slide.setAttribute('data-anim', 'fade');
      }
    });
  }, [swiperMedia]);

  // NOTE: progress bar animation end for image
  const handleAnimationEnd = (event: AnimationEvent) => {
    if (pauseVideo || event.elapsedTime === 0 || !swiperMedia) return;
    const currentItem = items[swiperMedia?.realIndex];
    if (currentItem?.video) return;

    onSlideNext();
  };

  // NOTE: handle video event
  const onVideoEnded = () => {
    if (pauseVideo) return;
    onSlideNext();
  };

  useEffect(() => {
    if (!swiperMedia || !isCloseSplash || !swiperMedia.pagination) return;
    // NOTE: progress bar animation end for image
    const BULLETS = swiperMedia.pagination?.bullets;
    BULLETS.forEach((o) => {
      o.addEventListener('animationend', handleAnimationEnd);
    });

    swiperMedia.slides.forEach((o) => {
      const VIDEO = o.querySelector('video') as HTMLVideoElement;
      if (VIDEO) {
        VIDEO.addEventListener('ended', onVideoEnded);
      }
    });

    onPlayMedia();

    return () => {
      BULLETS.forEach((o) => {
        o.removeEventListener('animationend', handleAnimationEnd);
      });

      swiperMedia.slides &&
        swiperMedia.slides.forEach((o) => {
          const VIDEO = o.querySelector('video') as HTMLVideoElement;
          if (VIDEO) {
            VIDEO.removeEventListener('ended', onVideoEnded);
          }
        });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swiperMedia, isCloseSplash]);

  useEffect(() => {
    if (!swiperMedia) return;

    const REAL_INDEX = swiperMedia.realIndex;
    const ACTIVE_SLIDE = swiperMedia.slides?.[swiperMedia.activeIndex];
    const ACTIVE_VIDEO = ACTIVE_SLIDE?.querySelector(
      'video'
    ) as HTMLVideoElement;

    if (!ACTIVE_VIDEO) return;

    // NOTE: reset slide
    swiperMedia.loopedSlides
      ? swiperMedia.slideToLoop(REAL_INDEX + 1, 0)
      : swiperMedia.slideTo(REAL_INDEX + 1, 0);

    swiperMedia.loopedSlides
      ? swiperMedia.slideToLoop(REAL_INDEX, 0)
      : swiperMedia.slideTo(REAL_INDEX, 0);
  }, [isPortrait, swiperMedia]);

  const onSlideNext = () => {
    if (!swiperMedia || pauseVideo) return;
    swiperMedia.slideNext();
  };

  const onOpenModal = (id: string) => {
    if (swiperMedia?.animating || !id) return;
    modal.open({ youtubeID: id });
    onPauseVideo();
    document.querySelectorAll('.swiper').forEach((el: any) => {
      el.swiper?.keyboard?.disable();
    });
  };

  const onCloseModal = () => {
    onPlayVideo();
    document.querySelectorAll('.swiper').forEach((el: any) => {
      el.swiper?.keyboard?.enable();
    });
  };

  const { api: modal, component: modalComponent } = useModal({
    type: 'video',
    onClose: onCloseModal,
  });

  const handleCursorButton = () => {
    const youtubeId = items[activeSlide]?.youtube?.url;
    if (youtubeId) {
      onOpenModal(youtubeId);
    }
  };

  if (items?.length === 0) return null;

  return (
    <>
      <HeroSection
        data-test="section_banner"
        data-impression="home-banner"
        className="h-screen-w-header"
        ref={areaRef}
      >
        {!!title && (
          <Text as="h1" size="h1" sr-only>
            {title}
          </Text>
        )}

        <Swiper
          ref={swiperRef as unknown as LegacyRef<SwiperRef>}
          modules={[Pagination, Keyboard]}
          rewind
          keyboard
          speed={1000}
          slidesPerView={1}
          threshold={10}
          pagination={{
            el: '.swiper-pagination-home-banner',
            clickable: true,
            renderBullet: function (index, className) {
              return `<div class="${className}" data-test="pagination_${
                index + 1
              }" data-hide-cursor="true"><span>${index + 1}</span>
                </div>`;
            },
          }}
          onSlideChange={(e) => setActiveSlide(e.realIndex)}
          onSwiper={setSwiperMedia}
          onSlideChangeTransitionStart={onResetActiveVideo}
          onSlideChangeTransitionEnd={onPlayMedia}
          data-test={`banner_items`}
        >
          {items?.slice(0, 9).map((item, i) => (
            <SwiperSlide
              key={i}
              data-test={`banner_item_${i + 1}`}
              data-track-section="home-banner-slider"
              data-track-value={i + 1}
              data-track-text={item?.preTitle || item?.title || ''}
            >
              <Video
                imagePriority={i === 0 && isCloseSplash}
                imageRootMargin={isCloseSplash ? '0px' : '-1px'}
                imageSrc={item.image?.src ?? ''}
                imageAlt={item.image?.alt ?? ''}
                imageWidth={600}
                imageHeight={800}
                imageSources={generateBannerImageSources({
                  mobileSrc: item.image?.src,
                  mobileOptions: {
                    quality: item.video?.src ? 60 : undefined,
                  },
                  desktopSrc: item.imageDesktop?.src,
                  desktopOptions: {
                    quality: item.videoDesktop?.src ? 60 : undefined,
                  },
                })}
                autoplay={false}
                video={item?.video?.src}
                videoDesktop={item?.videoDesktop?.src}
              />
              <BannerContentWithLogo
                itemId={i}
                item={item}
                dataTest="banner"
                dataTrack={dataTrack}
                isCloseSplash={isCloseSplash}
                onClick={() => onOpenModal(item?.youtube?.url ?? '')}
                containerElement={swiperRef.current as unknown as HTMLElement}
              />
            </SwiperSlide>
          ))}
        </Swiper>

        {items?.length > 1 && (
          <>
            <PaginationBar
              animateBanner
              startAnimateBanner={startAnimatePagination}
              pauseActiveBar={pauseVideo}
              variant="red"
              justify={{ '@lg': 'start' }}
              css={{
                position: 'absolute',
                left: 0,
                zIndex: 3,
                bottom: '$space-10',
                '@xs': {
                  bottom: '$space-12',
                },
                '@sm': {
                  bottom: '$space-14',
                },
                '@md': {
                  bottom: '$space-16',
                },
                '@lg': {
                  bottom: 'calc($space-20 - $space-1 + $space-6)',
                },
              }}
            >
              <Container>
                <Box position="relative" display={{ '@lg': 'inline-flex' }}>
                  <HidePaginationBarWrapper data-hide-cursor="true" />
                  <div
                    className="swiper-pagination swiper-pagination-home-banner"
                    data-test="paginations"
                  />
                </Box>
              </Container>
            </PaginationBar>
          </>
        )}

        {!!isCloseSplash && (
          <PlayerCursorButton
            areaRef={areaRef}
            handleClick={handleCursorButton}
            isActive={!!items[activeSlide]?.youtube?.url}
            icon="play"
            test={{ dataTest: 'banner_playButton' }}
          />
        )}
      </HeroSection>
      {modalComponent}
    </>
  );
};

LCVHomeBanner.displayName = 'SectionEinsteinHomeBanner';
export default LCVHomeBanner;

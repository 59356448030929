import type { CSS } from '@stitches/react';
import React from 'react';

import { Button } from '@/components/shared/buttons/button';
import { YoutubeButton } from '@/components/shared/einstein/banner-content-with-logo/youtube-button';
import { Text } from '@/components/shared/element/text';
import { Box } from '@/components/shared/layout/box';
import { Container } from '@/components/shared/layout/container';
import { Image } from '@/components/shared/media/image';
import { removeSpan } from '@/components/shared/utility/format';
import { styled } from '@/stitches.config';
import { IItem, IMy24Theme, IMy24Tracking } from '@/types/shared';

const StyledBannerContent = styled(Box, {
  textAlign: 'center',
  transform: 'translateZ(0)',
  pe: 'none',
  backfaceVisibility: 'hidden',
  transformStyle: 'preserve-3d',
  willChange: 'transform',
  '@lg': {
    textAlign: 'left',
  },
  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    width: '100%',
    left: 0,
    pe: 'none',
  },
  '&::before': {
    top: 0,
    height: 200,
    linearGradient: 'rgba(0, 0, 0, 0.25) 0%, rgb(0, 0, 0, 0) 100%',
    '@lg': {
      height: '100%',
      linearGradient: '25deg, rgb(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0) 50%',
    },
  },
  '&::after': {
    bottom: 0,
    height: 300,
    zIndex: -1,
    linearGradient: 'rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0, 0.25) 100%',
    '@lg': {
      content: 'none',
    },
  },
  '& > *': {
    height: '100%',
  },
});

const BannerContent = styled(Box, {
  position: 'relative',
  height: '100%',
  paddingTop: '$space-10',
  paddingBottom: 'calc($space-10 + $space-1 + $space-11)',
  transform: 'translateZ(0)',
  '@xs': {
    paddingTop: '$space-12',
    paddingBottom: 'calc($space-12 + $space-1 + $space-11)',
  },
  '@sm': {
    paddingTop: '$space-14',
    paddingBottom: 'calc($space-14 + $space-1 + $space-11)',
  },
  '@md': {
    paddingTop: '$space-16',
    paddingBottom: 'calc($space-16 + $space-1 + $space-11)',
  },
  '@lg': {
    paddingTop: '$space-20',
    paddingBottom: '$space-20',
  },
  variants: {
    isOnlyChild: {
      true: {
        paddingBottom: '$space-10',
        '@xs': {
          paddingBottom: '$space-10',
        },
        '@sm': {
          paddingBottom: '$space-14',
        },
        '@md': {
          paddingBottom: '$space-16',
        },
        '@lg': {
          paddingBottom: '$space-16',
        },
      },
    },
  },
});

const BannerContentLogo = styled(Box, {
  width: 'calc(200px + (300 - 200) * ((100vw - 360px) / (768 - 360)))',
  mx: 'auto',
  '@md': {
    width: 300,
  },
  '@lg': {
    width: 'calc(232px + (300 - 232) * ((100vw - 1024px) / (1440 - 1024)))',
    mx: '$space-0',
  },
  '@2xl': {
    width: 300,
  },
});

const BannerContentTitle = styled(Text, {
  textShadow: '0px 2px 6px rgba(0, 0, 0, 0.08)',
  mx: 'auto',
  '@lg': {
    mx: '$space-0',
  },
});

const HideCursorContentWrapper = styled(Box, {
  display: 'none',
  '@lg': {
    position: 'absolute',
    inset: '-$space-12',
    maxWidth: 'calc($size-96 + $size-24)',
    pointerEvents: 'all',
    display: 'block',
  },
});

const HideButtonWrapper = styled(Box, {
  display: 'none',
  '@lg': {
    position: 'absolute',
    inset: '-$space-12',
    pointerEvents: 'all',
    display: 'block',
  },
});

interface BannerContentWithLogoProps
  extends React.ComponentProps<typeof StyledBannerContent> {
  css?: CSS;
  className?: string;
  theme?: IMy24Theme;
  item?: IItem;
  dataTest?: string;
  dataTrack?: IMy24Tracking;
  onClick?: () => void;
  isCloseSplash?: boolean;
  itemId?: number;
  isOnlyChild?: boolean;
  containerElement?: HTMLElement;
}

export const BannerContentWithLogo = ({
  css,
  className,
  item,
  itemId,
  isCloseSplash,
  containerElement,
  onClick,
  dataTrack,
  isOnlyChild,
  theme = 'dark',
  dataTest = 'banner',
}: BannerContentWithLogoProps) => {
  return (
    <StyledBannerContent
      css={css}
      className={className}
      position="absolute"
      top="0"
      left="0"
      width="full"
      height="full"
      zIndex="1"
    >
      <Container>
        <BannerContent
          display="flex"
          direction="column"
          justifyContent={{ '@initial': 'between', '@lg': 'end' }}
          rowGap={item?.preTitle ? '8' : '6'}
          isOnlyChild={isOnlyChild}
        >
          <Box
            css={{ position: 'relative' }}
            display="flex"
            direction="column"
            rowGap={item?.preTitle ? '4' : { '@initial': '4', '@sm': '6' }}
          >
            <HideCursorContentWrapper data-hide-cursor="true" />
            {!!item?.logo?.src && (
              <BannerContentLogo position="relative">
                <Image
                  priority={itemId === 0 && isCloseSplash}
                  rootRef={containerElement as HTMLElement}
                  rootMargin={isCloseSplash ? '0px 50% 0px 0px' : '-1px'}
                  src={item?.logo?.src}
                  alt={item?.logo?.alt}
                  width={item?.logo?.width}
                  height={item?.logo?.height}
                  layout="responsive"
                  objectFit="contain"
                  objectPosition={{
                    '@initial': 'bottom',
                    '@lg': 'left-bottom',
                  }}
                  sources={[
                    {
                      srcset: item?.logoDesktop?.src || item?.logo?.src,
                      media: { minWidth: 1024 },
                      options: { width: 320 },
                    },
                    {
                      srcset: item?.logo?.src,
                      media: { maxWidth: 1023 },
                      options: { width: 256 },
                    },
                  ]}
                  css={{
                    '@lg':
                      item?.logoDesktop?.width && item?.logoDesktop?.height
                        ? {
                            aspectRatio: `${item?.logoDesktop?.width} / ${item?.logoDesktop?.height}`,
                          }
                        : {},
                  }}
                />
              </BannerContentLogo>
            )}
            <Box
              direction="column"
              rowGap={{ '@initial': '2', '@xs': '3' }}
              css={{ transform: 'translateZ(0)' }}
            >
              {!!item?.preTitle && (
                <BannerContentTitle
                  as="h2"
                  size="text-h2"
                  font="bold"
                  variant={
                    theme === 'dark' ? 'text-white' : 'text-gray-darkest'
                  }
                  wrap
                  dangerouslySetInnerHTML={{
                    __html: item?.preTitle,
                  }}
                  data-test={`${dataTest}_pre_title`}
                />
              )}
              {!!item?.title && (
                <BannerContentTitle
                  as="h2"
                  size="text-sub-h2"
                  font="bold"
                  variant={
                    theme === 'dark' ? 'text-white' : 'text-gray-darkest'
                  }
                  wrap
                  dangerouslySetInnerHTML={{
                    __html: item?.title,
                  }}
                  data-test={`${dataTest}_title`}
                  css={{ maxWidth: '$size-96' }}
                />
              )}
            </Box>
          </Box>
          <Box
            display="flex"
            direction="column"
            justifyContent="center"
            rowGap="6"
            css={{
              position: 'relative',
              '@lg': isOnlyChild
                ? {}
                : {
                    paddingBottom: 'calc($space-10 + $space-1)',
                  },
            }}
          >
            {!!item?.youtube?.url && (
              <YoutubeButton
                onClick={onClick}
                dataTrack={dataTrack}
                dataTest={dataTest}
                item={item}
              />
            )}
            {!!item?.buttons && item?.buttons?.length > 0 && (
              <Box
                css={{
                  '@lg': {
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                  },
                }}
              >
                <HideButtonWrapper data-hide-cursor="true" />
                {item?.buttons?.map((button, i) => (
                  <Button
                    key={i}
                    border={button?.border}
                    label={button?.label}
                    icon={button?.icon}
                    variant={button?.color}
                    size={button?.size}
                    href={button?.url}
                    isMinWidth
                    target={button.target}
                    tracking={{
                      dataTrack: dataTrack ?? '',
                      dataTrackSection: dataTest,
                      dataTrackText: button?.label,
                      dataTrackValue: removeSpan(item?.title ?? ''),
                      dataTrackUrl: button?.url,
                    }}
                    test={{
                      dataTest: `${dataTest}_ctaButton_${i + 1}`,
                    }}
                    css={{
                      pe: 'all',
                    }}
                  />
                ))}
              </Box>
            )}
          </Box>
        </BannerContent>
      </Container>
    </StyledBannerContent>
  );
};

BannerContentWithLogo.displayName = 'BannerContentWithLogo';
